import SelectField, { SelectSizeProps } from '@/components/atoms/SelectField'
import { LETTER_YEAR } from '@/constant/text'
import useStore from '@/zustand/sotre'
import { makeStyles } from '@material-ui/core'
import React, { useMemo } from 'react'

export interface SelectorProps {
    disabled?: boolean
    size?: SelectSizeProps
    viewOnly?: boolean
    year: number
    onYearSelected: (year: number) => void
    yearOptions?: {
        id: number
        value: string
    }[]
}

const useStyle = makeStyles({
    root: {},
    viewOnly: {
        pointerEvents: 'none',
        '& svg': {
            display: 'none',
        },
    },
})

export default (props: SelectorProps) => {
    const { analysisState } = useStore()
    const classes = useStyle()
    const { dateSelector } = analysisState
    const selectOptions = useMemo(
        () => dateSelector.options.years.map((year) => ({ id: year, value: `${year}${LETTER_YEAR}` })),
        [dateSelector],
    )

    return (
        <div className={props.viewOnly ? classes.viewOnly : ''}>
            <SelectField
                size={props.size}
                readOnly={true}
                value={props.year}
                onChange={(e) => props.onYearSelected(e.target.value as number)}
                disabled={props.disabled}
                menuItems={props.yearOptions || selectOptions}
            />
        </div>
    )
}
