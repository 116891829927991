import Space from '@/components/atoms/Space'
import SelectorGroup from '@/components/molecules/SelectorGroup'
import {withLayout} from '@/components/template/Layout'
import {makeMonth, siteActivityApi, siteSubmissionApi} from '@/ghgApi'
import useAnalysis from '@/hooks/useAnalysis'
import {
    ActivityWithEmissionFactor,
    CalculationMethodTreeNode,
    Category,
    EmissionFactor,
    SaveActivity, Scope, SubmissionStatus,
} from '@/openapi/api'
import useStore from '@/zustand/sotre'
import {makeStyles} from '@material-ui/core'
import * as FileSaver from 'file-saver'
import React, {useEffect, useMemo, useState} from 'react'
import YearSelector from "@/components/template/Analysis/Selector/YearSelector";
import OutlineButton from "@/components/atoms/Button/OutlineButton";
import UploadFileIcon from "@/components/atoms/Icon/svg/UploadFileIcon";
import styled from 'styled-components'
import PrimaryButton from "@/components/atoms/Button/PrimaryButton";
import * as Encoding from "encoding-japanese";
import Papa, {ParseConfig} from "papaparse";
import {InputTreeLeafNode, InputTreeNode, InputTreeNodeEvents} from "@/utils/tree";
import {InputDate} from "@/zustand/slice/inputSlice";
import groupby from 'lodash.groupby'
// ====================================================================================
// Overview
// ====================================================================================
type EmissionFactorForDownload = EmissionFactor & {
    type: InputTreeNode['name']
    calculationMethod: InputTreeLeafNode['name']
    categoryId: Category['id']
    emissionFactorTableId: number
    quantity: number
    memo: string
    scope: Scope | undefined
}

const MAX_DECIMAL_DIGIT = 6

const Input = styled.input`
    display: none;
`

const useStyles = makeStyles({
    operationTitle: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    selectGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        columnGap: 20,
        fontWeight: 300,
        fontSize: 16,
        marginTop: '20px',
    },
})

const OverviewInner = () => {
    const {
        storeState,
        analysisState,
        emissionFactorTableNodes,
        scopeData,
        setMessage
    } = useStore()
    const classes = useStyles()
    const [trees, setTrees] = useState<InputTreeNode[]>([])
    const [ready, setReady] = useState(false)
    const [readyCount, setReadyCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [doResetData, setDoResetData] = useState(false)

    const site = useMemo(
        () => analysisState.selectedSite || storeState.selectedSite,
        [storeState.sites, analysisState.selectedSite, storeState.selectedSite],
    )

    // 組織の選択した年度を月毎に取得
    const oneYearDates = useMemo(() => {
        let dates: InputDate[] = []
        if (storeState.organization?.startMonth) {
            for (let i = 0; i < 12; i++) {
                const month = storeState.organization.startMonth + i
                if (month > 12) {
                    dates.push({year: analysisState.date.year + 1, month: month - 12})
                } else {
                    dates.push({year: analysisState.date.year, month: month})
                }
            }
        }
        return dates
    }, [storeState.organization, analysisState.date])

    const { setAnalysisDate } = useAnalysis()
    const [activities, setActivities] = useState<ActivityWithEmissionFactor[] | undefined>(undefined)
    const markReady = ((e: CustomEvent) => {
        if (!e.detail.error) {
            setReadyCount((preCount) => {
                return preCount + 1
            })
        } else console.warn(e.detail.error)
    }) as EventListener

    useEffect(() => {
        const hasEf = trees.every((t) => t.hasEmissionFactors)
        setReady(hasEf && !!activities)
    }, [readyCount, activities]);

    useEffect(() => {
        setReady(false)
        setReadyCount(0)
        setActivities(undefined)

        if (site?.id && analysisState.date.year) {
            siteActivityApi.getSiteYearActivityWithEmissionFactor(site.id, analysisState.date.year).then((res) => {
                setActivities(res.data.data)
            }).catch((err) => {
                if (err.response?.status != 404) console.warn('SiteActivityApi.getSiteYearActivity:', err)
            })
        }

        let treeData: InputTreeNode[] = []
        const time = analysisState.date as InputDate || undefined
        // 全カテゴリのInputTreeNode作成
        if (scopeData.length == 3 && scopeData[2].categories) {
            treeData.push(new InputTreeNode(scopeData[0].categories[0].calculationMethodTree as CalculationMethodTreeNode, time, scopeData[0]))
            treeData.push(new InputTreeNode(scopeData[0].categories[0].calculationMethodTree as CalculationMethodTreeNode, { ...time, year: time.year - 1 }, scopeData[0]))
            treeData.push(new InputTreeNode(scopeData[1].categories[0].calculationMethodTree as CalculationMethodTreeNode, time, scopeData[1]))
            treeData.push(new InputTreeNode(scopeData[1].categories[0].calculationMethodTree as CalculationMethodTreeNode, { ...time, year: time.year - 1 }, scopeData[1]))
            scopeData[2].categories.forEach((c) => {
                treeData.push(new InputTreeNode(c.calculationMethodTree as CalculationMethodTreeNode, time, scopeData[2]))
                treeData.push(new InputTreeNode(c.calculationMethodTree as CalculationMethodTreeNode, { ...time, year: time.year - 1 }, scopeData[2]))
            })
        }
        treeData.forEach((t) => {
            t.target.addEventListener(InputTreeNodeEvents.EMISSION_FACTORS_GET, markReady)
        })
        setTrees(treeData)
        return () => {
            treeData.forEach((t) => {
                t.target.removeEventListener(InputTreeNodeEvents.EMISSION_FACTORS_GET, markReady)
            })
        }
    }, [analysisState.date, site, doResetData]);

    const onYearSelected = (year: number) => {
        setAnalysisDate('year', year)
    }

    const getAllEFFactor = (tree: InputTreeNode) => {
        const allEFFactor: EmissionFactorForDownload[][] = []
        const flattenEf = (inputTreeNode: InputTreeNode[]) => {
            inputTreeNode.every((child) => {
                if (child.children) {
                    flattenEf(child.children)
                    return true
                } else if (child.emissionFactors) {
                    const eFforDownload = child.emissionFactors.map((eF) => ({
                        ...eF,
                        type: child.nameBelowRoot,
                        calculationMethod: child.name,
                        categoryId: child.id,
                        quantity: NaN,
                        emissionFactorTableId: child.emissionFactorTableId,
                        memo: '',
                        scope: child.scope,
                    })) as EmissionFactorForDownload[]
                    allEFFactor.push(eFforDownload)
                    return true
                }
            })
        }
        if (tree.children) flattenEf(tree.children)
        const allEmissionFactors = allEFFactor.flat()
        return allEmissionFactors
    }

    const handleCsvDownload = () => {
        if (trees.length && site) {
            const allEFFactor: EmissionFactorForDownload[][] = []
            trees.forEach((t) => {
                const data = getAllEFFactor(t)
                allEFFactor.push(data)
            })

            const csvText = emissionFactorsToCsvContent(allEFFactor.flat())

            const unicodeList = []
            for (let i = 0; i < csvText.length; i += 1) {
                unicodeList.push(csvText.charCodeAt(i))
            }

            // https://qiita.com/fumihiko-hidaka/items/1fb8933072db76214079
            // 変換処理の実施
            const shiftJisCodeList = Encoding.convert(unicodeList, 'SJIS', 'UNICODE')
            const uInt8List = new Uint8Array(shiftJisCodeList)
            const writeData = new Blob([uInt8List], {
                type: 'text/csv;charset=SHIFT_JIS',
            })

            // ダウンロード実行
            const fileName = `scope-all-input.csv`
            FileSaver.saveAs(writeData, fileName)
        }

        function emissionFactorsToCsvContent(emissionFactors: Array<EmissionFactorForDownload>): string {
            const csvContent: any[] = []
            if (!activities) return ''

            const boundaryDate = new Date(`${oneYearDates[0].year}-04-01T00:00:00+09:00`)
            const previousYearActivities = activities.filter((a) => {
                const activityDate = new Date(a.month!)
                return activityDate < boundaryDate
            })
            const currentYearActivities = activities.filter((a) => {
                const activityDate = new Date(a.month!)
                return activityDate >= boundaryDate
            })
            oneYearDates.forEach((date) => {
                const targetActivities = new Date(`${date.year}-${date.month.toString().padStart(2, '0') }-01T00:00:00+09:00`) < boundaryDate ? previousYearActivities : currentYearActivities
                const uniqueActivityMap = new Map<string, ActivityWithEmissionFactor>()
                targetActivities.forEach((a) => {
                    uniqueActivityMap.set(`${a.categoryEmissionFactorTableId}-${a.emissionFactorId}`, a)
                })
                const uniqueActivities = Array.from(uniqueActivityMap.values())
                uniqueActivities.forEach((ua) => {
                    const targetActivity = targetActivities.find((ta) => {
                        const activityDate = new Date(ta.month!)
                        const activityYear = activityDate.getFullYear()
                        const activityMonth = activityDate.getMonth() + 1
                        return activityYear === date.year
                            && activityMonth === date.month
                            && ta.categoryEmissionFactorTableId === ua.categoryEmissionFactorTableId
                            && ta.emissionFactorId === ua.emissionFactorId
                    })
                    if (targetActivity) {
                        const emissionFactor = emissionFactors.find((ef) => { return ef.categoryId === targetActivity.categoryEmissionFactorTableId && ef.id === targetActivity.emissionFactorId })
                        csvContent.push({
                            拠点名: site?.name,
                            年: date.year,
                            月: date.month,
                            スコープ: emissionFactor?.scope?.name,
                            分類: emissionFactor?.type,
                            算定方法: emissionFactor?.calculationMethod,
                            入力項目: emissionFactor?.name,
                            活動量: targetActivity.quantity,
                            単位: emissionFactor?.unit,
                            '排出係数ID(編集禁止)': targetActivity.emissionFactorId,
                            'カテゴリーID(編集禁止)': targetActivity.categoryEmissionFactorTableId,
                            '拠点ID(編集禁止)': site?.id,
                            メモ: targetActivity?.memo != null ? targetActivity.memo.replace(/\n/g, "\\n") : ''
                        })
                    } else {
                        const emissionFactor = emissionFactors.find((ef) => { return ef.categoryId === ua.categoryEmissionFactorTableId && ef.id === ua.emissionFactorId })
                        csvContent.push({
                            拠点名: site?.name,
                            年: date.year,
                            月: date.month,
                            スコープ: emissionFactor?.scope?.name,
                            分類: emissionFactor?.type,
                            算定方法: emissionFactor?.calculationMethod,
                            入力項目: emissionFactor?.name,
                            活動量: undefined,
                            単位: emissionFactor?.unit,
                            '排出係数ID(編集禁止)': ua.emissionFactorId,
                            'カテゴリーID(編集禁止)': ua.categoryEmissionFactorTableId,
                            '拠点ID(編集禁止)': site?.id,
                            メモ: ua.memo != null ? ua.memo.replace(/\n/g, "\\n") : ''
                        })

                    }
                })
            })
            return Papa.unparse(csvContent)
        }
    }

    const handleFileUpload = (e: any) => {
        const file = e.target.files[0]
        if (file) {
            let reader = new FileReader()
            reader.onload = onLoadCSV

            reader.readAsArrayBuffer(file)

            // 同じファイルをアップできるため
            e.target.value = null
        }
        function onLoadCSV(e: ProgressEvent<FileReader>) {
            const yearIndex = 1
            const monthIndex = 2
            const quantityIndex = 7
            const eFIdIndex = 9
            const categoryIndex = 10
            const siteIdIndex = 11
            const memoIndex = 12

            if (e && e.target) {
                const codes = new Uint8Array(e.target.result as ArrayBuffer)
                const encoding = Encoding.detect(codes)
                const unicodeString = Encoding.convert(codes, {
                    to: 'UNICODE',
                    from: encoding as Encoding.Encoding,
                    type: 'string',
                })
                const parseConfig: ParseConfig = {
                    skipEmptyLines: true,
                }
                const data = Papa.parse(unicodeString, parseConfig).data
                const headlessData: string[][] = data.slice(1).map((d: string[]) => {
                    const result = d.map((inner, i) => {
                        if (i === 1) {
                            const replaceName: string = inner.split(' > ').reverse().shift() || inner
                            return replaceName
                        }
                        return inner
                    })
                    return result
                })

                const errors = validate(headlessData)
                if (!errors.length) {
                    importData(headlessData)
                } else {
                    setMessage({ message: errors[0], type: 'error' })
                    errors.forEach((error) => console.warn(error))
                }
            }

            function validate(headlessData: Array<any>): Array<string> {
                const errors = new Array<string>()
                headlessData.forEach((row, index) => {
                    const quantity = row[quantityIndex]
                    // 活動量がnumberであること
                    if (isNaN(Number(quantity))) errors.push(`${index + 1}番目：活動量は番号ではありません`)
                    if (Number(quantity) < 0) errors.push(`${index + 1}番目：活動量がマイナスの値になってます`)

                    const [integerPart, decimalPart] = quantity.split('.')
                    if (integerPart?.length > 12) errors.push(`${index + 1}番目：最大12桁まで`)
                    if (decimalPart?.length > MAX_DECIMAL_DIGIT) errors.push(`${index + 1}番目：小数点以下${MAX_DECIMAL_DIGIT}桁まで`)

                    if (Number(row[siteIdIndex]) != site?.id) errors.push(`${index + 1}番目：拠点IDが選択されたIDと一致していません`)

                    const year = Number(row[yearIndex])
                    const month = Number(row[monthIndex])
                    if (isNaN(year) || isNaN(month) || !oneYearDates.some((date) => (date.year == year && date.month == month))) {
                        errors.push(`${index + 1}番目：年月が選択された年度内ではありません`)
                    }
                    const validCategory = activities?.some((a) => a.emissionFactorId == Number(row[eFIdIndex]) && a.categoryEmissionFactorTableId == Number(row[categoryIndex]))
                    if (!validCategory) {
                        errors.push(`${index + 1}番目：登録済みのカテゴリではありません`)
                    }
                })

                return errors
            }

            function importData(headlessData: Array<any>) {
                setLoading(true)
                const save_activities: Array<SaveActivity> = []
                headlessData.forEach((d) => {
                    const quantity = Number(d[quantityIndex])
                    const categoryId = Number(d[categoryIndex])
                    const memo = d[memoIndex] as string
                    const eF_id = Number(d[eFIdIndex])
                    const month = makeMonth(Number(d[yearIndex]), Number(d[monthIndex]))
                    if ((quantity || d[quantityIndex] == '0') && eF_id) {
                        const registered = activities?.find((a) => {
                            return a.emissionFactorId == eF_id
                            && a.categoryEmissionFactorTableId == categoryId
                            && a.month == month
                        })
                        save_activities.push({
                            id: registered?.id,
                            emissionFactorId: eF_id,
                            categoryEmissionFactorTableId: categoryId,
                            quantity: quantity,
                            month: month,
                            memo: memo.replace(/\\n/g, '\n')
                        })
                    }
                })
                if (site?.id && save_activities.length) {
                    const groupSaveActivities = groupby(save_activities, a => a.month)
                    const saveProcess = Promise.all(
                        Object.entries(groupSaveActivities).map(([key, value]) => {
                            return siteActivityApi.saveSiteActivities(site.id, key, {activities: value})
                        })
                    )

                    saveProcess.then(() => {
                        Promise.all(
                            Object.entries(groupSaveActivities).map(([key, _value]) => {
                                return siteSubmissionApi.saveSiteSubmissions(site.id, {
                                    month: key,
                                    status: SubmissionStatus.Done,
                                })
                            })
                        ).then(() => {
                            setLoading(false)
                            setMessage({ message: "アップロードに成功しました", type: 'success' })
                            setDoResetData((pre) => !pre)
                        }).catch((e) => {
                            setLoading(false)
                            console.error(e)
                            setMessage({ message: "アップロードに失敗しました。", type: 'error' })
                        })

                    })

                } else {
                    setLoading(false)
                    setMessage({ message: "アップロード対象が存在しません", type: 'error' })
                }
            }
        }
    }

    return (
        <main>
            <title>データ入力｜ScopeX</title>
            <SelectorGroup/>
            <Space height={15}/>
            <div className={classes.operationTitle}>CSVの一括ダウンロード (β版)</div>
            <div className={classes.selectGroup}>
                <div style={{width: 224, minWidth: 224}}>
                    <YearSelector size="sm" onYearSelected={onYearSelected} year={analysisState.date.year}/>
                </div>
                <PrimaryButton
                width={180}
                height={35}
                fontSize={14}
                onClick={handleCsvDownload}
                disabled={!ready}
                >
                CSVダウンロード
                </PrimaryButton>
            </div>
            <Space/>
            <div className={classes.operationTitle}>CSVの一括アップロード</div>
            <label htmlFor="item-selector-csv-handler-upload" onChange={handleFileUpload}>
                <Input accept="text/csv" type="file" id="item-selector-csv-handler-upload"/>
                <OutlineButton
                    startIcon={<UploadFileIcon/>}
                    style={{marginTop: 20, width: 164, height: 35, fontSize: 14, fontWeight: 300}}
                    component="span"
                    disabled={loading}
                >
                    アップロード
                </OutlineButton>
            </label>
        </main>
    )
}

const Overview = () => <OverviewInner/>

export default withLayout(Overview)
